
module.exports = {
  pathPrefix: '/',
  siteUrl: 'https://corona-virusblog.com',
  siteTitle: 'COVID-19 Blog',
  siteDescription: 'A blog about everything related to COVID-19',
  author: 'COVID Data Analyst',
  postsForArchivePage: 10,
  defaultLanguage: 'en',
  disqusScript: 'https://Aravind-Hande.disqus.com/embed.js',
  pages: {
    home: '/',
    blog: 'blog',
    about: 'about',
    tag: 'tag',
    archive: 'archive'
  },
  social: {
    github: 'https://github.com/aravindhande',
    linkedin: '',
    rss: '/rss.xml'
  },
  tags: {
    alabama: { 
      description: 'Alabama is a southeastern U.S. state that’s home to significant landmarks from the American Civil Rights Movement. Capital is Montgomery. Population is 4.90 million. Governor is Kay Ivey (Republican)'
    },
    arkansas: { 
      description: 'Arkansas is a southern U.S. state bordering the Mississippi River. Capital is Little Rock. Population is 3.02 million. Governor is Asa Hutchinson (Republican)'
    },
    alaska: { 
      description: "Alaska is a U.S. state on the northwest extremity of the country's West Coast, just across the Bering Strait from Asia. Capital is Anchorage. Population is 0.71 million. Governor is Mike Dunleavy (Republican)"
    },
    arizona: { 
      description: 'Arizona, a southwestern U.S. state, is best known for the Grand Canyon, the mile-deep chasm carved by the Colorado River. Capital is Phoenix. Population is 7.28 million. Governor is Doug Ducey (Republican)'
    },
    california: { 
      description: 'California, a western U.S. state, stretches from the Mexican border along the Pacific for nearly 900 miles. Capital is Sacramento. Population is 39.51 million. Governor is Gavin Newsom (Democrat)'
    },
    colorado: { 
      description: 'Colorado is a state in the western United States encompassing most of the southern Rocky Mountains as well as the northeastern portion of the Colorado Plateau and the western edge of the Great Plains. Capital is Dever. Population is 5.76 million. Governor is Jared Polis (Democrat)'
    },
    connecticut: { 
      description: 'Connecticut is the southernmost state in the New England region of the northeastern United States. Capital is Hartford. Population is 3.57 million. Governor is Ned Lamont (Democrat)'
    },
    delaware: { 
      description: 'Delaware is located in the Mid-Atlantic region. It is bordered to the south and west by Maryland, north by Pennsylvania, and east by New Jersey and the Atlantic Ocean. Capital is Dover. Population is 0.98 million. Governor is John Carney (Democrat)'
    },
    florida: { 
      description: 'Florida is the southeasternmost U.S. state, with the Atlantic on one side and the Gulf of Mexico on the other. It has hundreds of miles of beaches. Capital is Tallahassee. Population is 21.48 million. Governor is Ron DeSantis (Republican)'
    },
    georgia: { 
      description: 'Georgia is a southeastern U.S. state whose terrain spans coastal beaches, farmland and mountains. Capital is Atlanta. Population is 10.62 million. Governor is Brian Kemp (Republican)'
    },
    hawaii: { 
      description: 'Hawaii is located in the Pacific Ocean. It is the only U.S. state located outside North America, the only island state, and the only state in the tropics. Capital is Honolulu. Population is 1.42 million. Governor is David Ige (Democrat)'
    },
    idaho: { 
      description: 'Idaho is a state in the Pacific Northwest region of the United States. It borders the state of Montana to the east and northeast, Wyoming to the east, Nevada and Utah to the south, and Washington and Oregon to the west. To the north, it shares a small portion of the Canadian border with the province of British Columbia. Capital is Boise. Population is 1.79 million. Governor is Brad Little (Republican)'
    },
    illinois: { 
      description: 'Illinois is a state in the Midwestern and Great Lakes regions of the United States. Capital is Sacramento. Population is 12.67 million. Governor is J. B. Pritzker (Democrat)'
    },
    indiana: { 
      description: 'Indiana is a U.S. state in the Midwestern and Great Lakes regions of North America. Capital is Indianapolis. Population is 6.73 million. Governor is Eric Holcomb (Republican)'
    },
    iowa: { 
      description: 'Iowa is a state in the Midwestern United States, bordered by the Mississippi River to the east and the Missouri River and Big Sioux River to the west. It is bordered by six states: Wisconsin to the northeast, Illinois to the east and southeast, Missouri to the south, Nebraska to the west, South Dakota to the northwest, and Minnesota to the north. Capital is Des Moines. Population is 3.16 million. Governor is Kim Reynolds (Republican)'
    },
    kansas: { 
      description: 'Kansas is a U.S. state in the Midwestern United States. Capital is Topeka. Population is 2.91 million. Governor is Laura Kelly (Democrat)'
    },
    kentucky: { 
      description: 'Kentucky is a southeastern state bounded by the Ohio River in the north and the Appalachian Mountains in the east. Capital is Frankfort. Population is 4.47 million. Governor is	Andy Beshear (Democrat)'
    },
    louisiana: { 
      description: 'Louisiana is a southeastern U.S. state on the Gulf of Mexico. Its history as a melting pot of French, African, American and French-Canadian cultures is reflected in its Creole and Cajun cultures. Capital is Baton Rouge. Population is 4.65 million. Governor is John Bel Edwards (Democrat)'
    },
    maine: { 
      description: 'Maine is the northeasternmost state in the Northeastern United States. Capital is Augusta. Population is 1.34 million. Governor is Janet Mills (Democrat)'
    },
    maryland: { 
      description: 'Maryland is a state in the Mid-Atlantic region of the Southeastern United States, bordering Virginia, West Virginia, and the District of Columbia to its south and west, Pennsylvania to its north, and Delaware and the Atlantic Ocean to its east Capital is Baltimore. Population is 6.05 million. Governor is Larry Hogan (Republican)'
    },
    massachusetts: { 
      description: 'Massachusetts is the most populous state in the New England region of the northeastern United States. It borders on the Atlantic Ocean to the east, the states of Connecticut and Rhode Island to the south, New Hampshire and Vermont to the north, and New York to the west. Capital is Boston. Population is 6.89 million. Governor is Charlie Baker (Republican)'
    },
    minnesota: { 
      description: 'Minnesota is a state in the Upper Midwest, Great Lakes, and northern regions of the United States. Capital is Saint Paul. Population is 5.64 million. Governor is Tim Walz (Republican)'
    },   
    michigan: { 
      description: 'Michigan is a state in the Great Lakes and Midwestern regions of the United States. Capital is Lansing. Population is 9.88 million. Governor is Gretchen Whitmer (Democrat)'
    },
    mississippi: { 
      description: 'Mississippi is a southern U.S. state with the Mississippi River to its west, the state of Alabama to its east, and the Gulf of Mexico to the south. Capital is Jackson. Population is 2.98 million. Governor is Tate Reeves (Republican)'
    },        
    missouri: { 
      description: 'Missouri is a state in the Midwestern United States. Capital is Jefferson City. Population is 6.14 million. Governor is Mike Parson (Republican)'
    },
    montana: { 
      description: 'Montana is a state in the Northwestern United States. It is bordered by the Canadian provinces of Alberta, British Columbia, and Saskatchewan to the north; Idaho to the west; North Dakota and South Dakota to the east; and Wyoming to the south. Capital is Helena. Population is 1.07 million. Governor is	Steve Bullock  (Democrat)'
    },
    nebraska: { 
      description: 'Nebraska is a state that lies both in the Great Plains and in the Midwestern United States. It is bordered by South Dakota to the north; Iowa to the east and Missouri to the southeast, both across the Missouri River; Kansas to the south; Colorado to the southwest; and Wyoming to the west. It is the only triply landlocked U.S. state. Capital is Lincoln. Population is 1.93 million. Governor is Pete Ricketts (Republican)'
    },
    nevada: { 
      description: 'Nevada is a state in the Western United States. It is bordered by Oregon to the northwest, Idaho to the northeast, California to the west, Arizona to the southeast, and Utah to the east. Capital is Carson City. Population is 3.08 million. Governor is Steve Sisolak (Democrat)'
    },
    "new hampshire": { 
      description: 'New Hampshire is a state in the New England region of the northeastern United States. It is bordered by Massachusetts to the south, Vermont to the west, Maine and the Atlantic Ocean to the east, and the Canadian province of Quebec to the north. Capital is Concord. Population is 1.36 million. Governor is Chris Sununu (Republican)'
    },
    "new jersey": { 
      description: 'New Jersey is a state in the Mid-Atlantic region of the Northeastern United States. It is bordered on the north and east by the state of New York; on the east, southeast, and south by the Atlantic Ocean; on the west by the Delaware River and Pennsylvania; and on the southwest by Delaware Bay and the State of Delaware. Capital is Trenton. Population is 8.88 million. Governor is Phil Murphy (Democrat)'
    },
    "new mexico": { 
      description:  'New Mexico is a state in the Southwestern region. Capital is Santa Fe. Population is 2.10 million. Governor is Michelle Lujan Grisham (Democrat)'
    },
    "new york": { 
      description: 'New York is a state in the Northeastern United States. New York was one of the original thirteen colonies that formed the United States. Capital is Albany. Population is 19.45 million. Governor is Andrew Cuomo (Democrat)'
    },
    "north carolina": { 
      description: 'North Carolina is a state in the southeastern region of the United States. Capital is Raleigh. Population is 10.49 million. Governor is Roy Cooper (Democrat)'
    },
    "north dakota": { 
      description: 'North Dakota is a U.S. state in the midwestern and northern regions of the United States. Capital is Bismarck. Population is 0.76 million. Governor is Doug Burgum (Republican)'
    },
    ohio: { 
      description: 'Ohio is a state in the East North Central region of the Midwestern United States. Capital is Columbus. Population is 11.69 million. Governor is Mike DeWine (Republican)'
    },
    oklahoma: { 
      description: 'Oklahoma is a state in the South Central region of the United States, bordered by the state of Texas on the south and west, Kansas on the north, Missouri on the northeast, Arkansas on the east, New Mexico on the west, and Colorado on the northwest. Capital is Oklahoma City. Population is 3.96 million. Governor is Kevin Stitt (Republican)'
    },
    oregon: { 
      description: "Oregon is a state in the Pacific Northwest region on the West Coast of the United States. The Columbia River delineates much of Oregon's northern boundary with Washington, while the Snake River delineates much of its eastern boundary with Idaho. The 42° north parallel delineates the southern boundary with California and Nevada. Capital is Salem. Population is 4.22 million. Governor is Kate Brown (Democrat)"
    },
    pennsylvania: { 
      description: 'Pennsylvania is a state founded by William Penn in the Northeastern, Great Lakes, Appalachian, and Mid-Atlantic regions of the United States. The Appalachian Mountains run through its middle. The Commonwealth is bordered by Delaware to the southeast, Maryland to the south, West Virginia to the southwest, Ohio to the west, Lake Erie and the Canadian province of Ontario to the northwest, New York to the north, and New Jersey to the east. Capital is Harrisburg. Population is 12.80 million. Governor is Tom Wolf (Democrat)'
    },
    "rhode island": { 
      description: 'Rhode Island is a state in the New England region of the United States. It is the smallest U.S. state by area and the seventh least populous, but it is also the second most densely populated. The state has land borders with Connecticut to the west, Massachusetts to the north and east, and the Atlantic Ocean to the south via Rhode Island Sound and Block Island Sound. It also shares a small maritime border with New York. Capital is Providence. Population is 1.06 million. Governor is Gina Raimondo (Democrat)'
    },
    "south carolina": { 
      description: 'South Carolina is a southeastern U.S. state known for its shoreline of subtropical beaches and marshlike sea islands. Capital is Columbia. Population is 5.15 million. Governor is Henry McMaster (Republican)'
    },
    "south dakota": { 
      description: 'South Dakota is a U.S. state in the Midwestern region of the United States. Capital is Pierre. Population is 0.88 million. Governor is Kristi Noem (Republican)'
    },
    tennessee: { 
      description: 'Tennessee is a landlocked state in the U.S. South. Capital is Nashville. Population is 6.83 million. Governor is Bill Lee (Republican)'
    },
    texas: { 
      description: 'Texas is a state in the South Central Region of the United States. It is the second largest U.S. state by both area and population. Capital is Austin. Population is 29 million. Governor is Greg Abbott (Republican)'
    },    
    utah: { 
      description: 'Utahis a state in the western United States. It is bordered by Colorado to the east, Wyoming to the northeast, Idaho to the north, Arizona to the south, and Nevada to the west. It also touches a corner of New Mexico in the southeast. Capital is Salt Lake City. Population is 3.21 million. Governor is Gary Herbert (Republican)'
    },
    vermont: { 
      description: 'Vermont is a northeastern state in the New England region of the United States. It borders the states of Massachusetts to the south, New Hampshire to the east, and New York to the west, and the Canadian province of Quebec to the north. Vermont is the only state in New England that does not border the Atlantic Ocean.  Capital is Montpelier. Population is 0.62 million. Governor is Phil Scott (Republican)'
    },
    virginia: { 
      description: 'Virginia, a southeastern U.S. state, stretches from the Chesapeake Bay to the Appalachian Mountains, with a long Atlantic coastline.  Capital is Richmond. Population is 8.54 million. Governor is Ralph Northam (Democrat)'
    },
    washington: { 
      description: 'Washington, is a state in the Pacific Northwest region of the United States. The state is bordered on the west by the Pacific Ocean, Oregon to the south, Idaho to the east, and the Canadian province of British Columbia to the north. Capital is Olympia. Population is 7.61 million. Governor is Jay Inslee (Democrat)'
    },
    "west virginia": { 
      description: 'West Virginia is a state in the Appalachian region of the Southern United States, though it is also considered part of the Mid-Atlantic Southeast Region.[Note 1] It is bordered by Pennsylvania to the northeast, Maryland to the east and northeast, Virginia to the southeast, Kentucky to the southwest, and Ohio to the northwest. Capital is Charleston. Population is 1.79 million. Governor is Jim Justice (Republican)'
    },
    wisconsin: { 
      description: 'Wisconsin is a U.S. state in the north-central, Midwest and Great Lakes regions of the country. It is bordered by Minnesota to the west, Iowa to the southwest, Illinois to the south, Lake Michigan to the east, Michigan to the northeast, and Lake Superior to the north.  Capital is Madison. Population is 5.82 million. Governor is Tony Evers (Democrat)'
    },
    wyoming: { 
      description: 'Wyoming is a landlocked state in the western United States. It is bordered by Montana to the north and northwest, South Dakota and Nebraska to the east, Colorado to the south, Utah to the southwest, and Idaho to the west. Capital is Cheyenne. Population is 0.58 million. Governor is Mark Gordon (Republican)'
    },
    "covid-19": {
      description: 'COVID-19 is a new illness that can affect your lungs and airways. It is caused by a virus called coronavirus. Also known as: SARS-CoV-2, 2019 Novel Coronavirus, nCov'
    },
    "daily-report" : {
      description: 'A brief description of the records that were set and milestones crossed on the day.'
    },
    "election": {
      description: 'The 2020 United States presidential election was the 59th quadrennial presidential election, held on Tuesday, November 3, 2020. The Democratic ticket of former vice president Joe Biden and U.S. senator Kamala Harris defeated the Republican ticket of incumbent president Donald Trump and vice president Mike Pence.'
    },
    "politics": {
      description: 'Politics is the set of activities that are associated with making decisions in groups, or other forms of power relations between individuals, such as the distribution of resources or status. The academic study of politics is referred to as political science.'
    },
    "weekly-report" : {
      description: 'A brief description of the records that were set and milestones crossed on every day of the week. In addition a summary of where things stand in each continent at the end of the week.'
    },
    "voter suppression" : {
      description: 'Voter suppression is a strategy used to influence the outcome of an election by discouraging or preventing specific groups of people from voting.'
    }          
  }
}